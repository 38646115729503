import * as React from "react";
import { Filter } from "@nike/nike-design-system-icons";
import { FormControl, MenuItem } from "@mui/material";
import { GridPanel } from "@mui/x-data-grid";
import { Link } from "gatsby";
import { facetOptions } from "../util/facetOptions";
import Button, { ButtonProps } from "@mui/material/Button";

export const GridFacetToolbar = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const [selected, setSelected] = React.useState(false);

  return (
    <>
      <Button
        ref={ref}
        size="small"
        color="primary"
        aria-label="Facet"
        startIcon={<Filter />}
        {...props}
        onClick={() => setSelected(!selected)}
      >
        CATEGORY
      </Button>
      {selected && (
        <GridPanel open={selected}>
          <FormControl fullWidth>
            {Object.entries(facetOptions).map(([_, val]) => (
              <Link to={`/${val.urlKey}`} key={val.urlKey}>
                <MenuItem>{val.displayName}</MenuItem>
              </Link>
            ))}
          </FormControl>
        </GridPanel>
      )}
    </>
  );
}) as (props: ButtonProps) => JSX.Element;
