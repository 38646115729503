import * as React from "react";
import {
  DataGrid,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import { FacetOptionsKeys, facetOptions } from "../util/facetOptions";
import { GridFacetToolbar } from "./GridFacetToolbar";
import { Link } from "gatsby";
import { Menu2lines } from "@nike/nike-design-system-icons";

const columnBuilder = (field: string, headerName: string, additional?: any) => ({
  field,
  headerName,
  flex: 1,
  ...additional
});

type TableDataQuery = {
  facetBy: Array<{
    field: FacetOptionsKeys;
    fieldValue: string;
    countries: number;
    events: number;
    views: number;
    platforms: number;
    tags: number;
    vendors: number;
    uniques: number;
    last_updated: string;
  }>;
};

export type TableDataProps = {
  data: TableDataQuery;
};

const TableDataRenderer: React.FC<TableDataProps> = ({ data }) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ backgroundColor: "#CCC" }}>
        <GridFacetToolbar />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector startIcon={<Menu2lines />} />
      </GridToolbarContainer>
    );
  }
  const uniquesColumn = columnBuilder("uniques", "Unique Count", {
    type: "number"
  });
  const rows = data.facetBy.map((row) => ({
    ...row,
    id: row.fieldValue,
    [row.field]: row.fieldValue
  }));
  const { field } = data.facetBy[0];
  const facetOption = facetOptions[field];

  const primaryColumn = columnBuilder(field, facetOption.displayName, {
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`/${facetOption.urlKey}/${params.value as string}`}>{params.value}</Link>
    ),
    flex: 2
  });
  const numericColumns = Object.entries(facetOptions)
    .filter(([key]) => key !== field)
    .map(([_key, value]) => columnBuilder(value.plural, value.displayName, { type: "number" }));

  const lastUpdatedColumn = columnBuilder("last_updated", "Last Updated", {
    type: "date"
  });

  const columns = [primaryColumn, ...numericColumns, uniquesColumn, lastUpdatedColumn];

  return (
    <DataGrid
      style={{
        flex: 1,
        minHeight: 800
      }}
      components={{ Toolbar: CustomToolbar }}
      autoHeight={false}
      rows={rows}
      rowHeight={50}
      columns={columns}
      density={"compact"}
    />
  );
};

export default TableDataRenderer;
