export const facetOptions = {
  event: {
    searchKey: "EVENT",
    urlKey: "event",
    displayName: "Event",
    plural: "events"
  },
  application_country: {
    searchKey: "COUNTRY",
    urlKey: "country",
    displayName: "Country",
    plural: "countries"
  },
  application_view: {
    searchKey: "VIEW",
    urlKey: "view",
    displayName: "Application View",
    plural: "views"
  },
  tag_name: {
    searchKey: "TAG",
    urlKey: "tag",
    displayName: "Tag",
    plural: "tags"
  },
  vendor: {
    searchKey: "VENDOR",
    urlKey: "vendor",
    displayName: "Vendor",
    plural: "vendors"
  },
  platform: {
    searchKey: "PLATFORM",
    urlKey: "platform",
    displayName: "Platform",
    plural: "platforms"
  }
};

type FacetOptions = typeof facetOptions;
export type FacetOptionsKeys = keyof FacetOptions;
type FacetValues = FacetOptions[FacetOptionsKeys];

export const facetOptionsFromPlural = (value: string): FacetValues | undefined =>
  Object.entries(facetOptions).find(([_key, v]) => v.plural === value)?.[1];
